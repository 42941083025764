import React from 'react'
import { Link } from 'gatsby';
import styled from '@emotion/styled'
import { breakpoints } from '../utils/styles'


const DrewSticker = ({ img, caption, bg, reverse, showName }) => {

  const StickerStyle = styled(Link)`

    max-width: 1420px;

    display: flex;
    flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
    background-color: ${bg};
    align-items: center;

    @media (min-width: ${breakpoints.m}px){
      margin: 20px auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    text-decoration: none;

    img {
      width: 100%;
      height: 100%;
      max-height: 500px;
      object-fit: cover;
      object-position: center;
      display: block;
    }


    h2 {
      font-family: 'HKNova-ExtraBold';
      letter-spacing: 0.10rem;
      font-weight: normal;
      font-size: 20px;
      margin-bottom: 35px;
      max-width: 420px;
      margin: auto;

      @media (min-width: ${breakpoints.l}px){
        font-size: 24px;
      }

    }

    h3 {
      letter-spacing: 0.350rem;
      font-family: 'gilroyExtraBold';
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px;

    }

    .caption-contain {
      padding: 30px;
      text-align: center;
      color: white;
    }
  `

  return (
    <StickerStyle reverse={reverse} to='/drew-brees'>
      { reverse && <div className='caption-contain'><h2>{ caption }</h2>{showName && <h3>Drew Brees</h3>}</div> }
      <div>
        <img src={ img } alt="Drew Brees - Strength To Heal" />
      </div>
      { !reverse && <div className='caption-contain'><h2>{ caption }</h2>{showName && <h3>Drew Brees</h3>}</div> }
    </StickerStyle>
  )

}

export default DrewSticker
