import React from 'react';
import { Link } from 'gatsby';
import {
  ReviewBlockStyle
} from '../../utils/styles';

import reviewCount from '../../images/5-stars.png';

const ReviewBlock = (props) => (<ReviewBlockStyle { ...props }>
  <div className="box">
    <div className='caption-area'>
      <h6>#1 Best Seller</h6>
      <h3>{ props.caption ? props.caption : 'Copper Compression' }</h3>
      <h2>{ props.reviewText }</h2>
      { props.children }

      <img className='review-count' src={reviewCount} alt="5 star review" />

      <h5>{ props.reviewCredit }</h5>
      <h5>{ props.reviewCount }</h5>
      { !props.hideCTA &&
        <Link className='cta' to={ props.destination ? props.destination : '/collections/all-1/' }>
          { props.ctaText ? props.ctaText : 'Shop' }
        </Link> }
    </div>
  </div>
  <div className="box box-image">
    <Link to={ props.destination ? props.destination : '/collections/all-1/' }>
      <img alt={ props.title } src={ props.boximage } />
    </Link>
  </div>
</ReviewBlockStyle>);


export default ReviewBlock;
