import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { skyBlue } from '../../constants/theme';
import { breakpoints } from '../../utils/styles';

const HeroBlock = ({image, subtitle, title, tagline, cta, to}) => {

  const HeroStyle = styled.div`

    max-width: 1400px;

    margin: auto;

    min-height: 700px;
    background-size: cover;
    background-position: center right;
    margin-top: 0;
    
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.xl}px){
      justify-content: flex-end;
      padding-bottom: 100px;
      min-height: 80vh;
      background-image: url('${image}')
    }
    @media (min-width: ${breakpoints.xl}px){
      justify-content: center;
      background-image: url('${image}')
    }
    h2 {
      font-size: 16px;
      font-family: 'HKNova-Regular';
      letter-spacing: 0.1rem;
      text-align: justify;
      line-height: 1.4;

      @media(max-width: ${breakpoints.m}px) {
        font-size: 14px;
      }

    }

    h3 {
      letter-spacing: 0.350rem;
      font-family: 'gilroyExtraBold';
      text-transform: uppercase;
      font-weight: normal;
      font-size: 18px;
      margin-bottom: 14px;
    }

    h1 {
      font-size: 60px;
      font-family: 'HKNova-Bold';
      letter-spacing: 0.1rem;
      font-weight: normal;
      margin-top: 0;
      line-height: 1;
      margin-bottom: 30px;

      @media(max-width: ${breakpoints.m}px) {
        font-size: 48px;
      }

      @media(max-width: ${breakpoints.s}px) {
        font-size: 32px;
      }
    }

    .caption {
      background-color: #df9978;
      color: white;
      max-width: 800px;
      padding: 20px;
      position: relative;
    }

    a {
      background-color: white;
      color: #df9978;
      display: block;
      position: absolute;
      bottom: 0;
      padding: 20px 40px;
      transform: translateY(70%);
      letter-spacing: 0.350rem;
      font-family: 'gilroyExtraBold';
      text-transform: uppercase;
      font-weight: normal;
      font-size: 18px;
      text-decoration: none;

      @media(max-width: ${breakpoints.m}px) {
        font-size: 14px;
        transform: translateY(85%);
      }


      &:hover {
        background-color: ${skyBlue};
        color: white;
      }
    }

  `


  return (
    <HeroStyle>
      <div className='caption'>
        <h3>{subtitle}</h3>
        <h1>{title}</h1>
        <h2>{tagline}</h2>
        <Link to={to}>{cta}</Link>
      </div>
    </HeroStyle>
  )
}

export default HeroBlock
