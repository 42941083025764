import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import BestSellers from '../components/BestSellers'
import { skyBlue } from '../constants/theme'
import { GuaranteeBlockStyle, ImitationsBlockStyle, PressItemsBlockStyle, LifestyleBlockStyle, ImageStripBlockStyle } from '../utils/styles'

import DrewSticker from '../components/drewSticker'
import ReviewBlock from '../components/ReviewBlock'
import HeroBlock from '../components/HeroBlock'

import Icon1 from '../images/icons/copper-icon-copper-content.png'
import Icon2 from '../images/icons/copper-icon-anti-microbial.png'
import Icon3 from '../images/icons/copper-icon-anti-odor.png'

import salesHome from '../images/homepage/drew-home.jpg'
import lifestyleImage2 from '../images/lifestyle-strip.jpg'
import drewSticker2 from '../images/drew/sticker2.jpg'
import handReview from '../images/hand-review-graphic.jpg'

import pressFastCompany from '../images/press/fastcompany.png'
import pressVice from '../images/press/vice.png'
import pressBustle from '../images/press/bustle.svg'
import pressElite from '../images/press/elite.svg'
import pressBuzzFeed from '../images/press/BuzzFeed.svg'

const GuaranteeBlock = () => (
  <GuaranteeBlockStyle className='guarantee-block'>
    <div className="contain">
      <h3>30-DAY Unconditional</h3>
      <h1>100% Satisfaction<br /> Guarantee</h1>
      <p>We back all of our products with a money-back guarantee. If you are not completely satisfied with this product for any reason, We will gladly refund your purchase price, excluding shipping and handling charges, within 30 days of purchase.
        <Link to='/warranty'>Read more about our no-risk money back guarantee</Link>
      </p>
    </div>
  </GuaranteeBlockStyle>
)

const ImitationsBlock = () => (
  <ImitationsBlockStyle className='imitations-block'>
    <h3>Setting a new Standard</h3>
    <h1>Copper Compression</h1>
  </ImitationsBlockStyle>
)

const PressBlock = () => (
  <PressItemsBlockStyle>
    <div className="contain">
      <a target="_blank" rel="noopener noreferrer" href="https://www.fastcompany.com/90476550/copper-kills-coronavirus-why-arent-our-surfaces-covered-in-it" className='press-item'>
        <img width="90%" src={ pressFastCompany } alt="Press Fast Company" />
        <p>"Civilizations have recognized copper’s properties for centuries. It’s time to bring the material back."</p>
      </a>

      <a target="_blank" rel="noopener noreferrer" href="https://www.vice.com/en_us/article/xgqkyw/copper-destroys-viruses-and-bacteria-why-isnt-it-everywhere" className='press-item'>
        <img width="90%" src={ pressVice } alt="Press Vice" />
        <p>"Copper is antimicrobial. It kills bacteria and viruses, sometimes within minutes."</p>
      </a>

      <a target="_blank" rel="noopener noreferrer" href="https://www.bustle.com/p/of-the-millions-of-products-on-amazon-these-35-highly-rated-things-always-stand-out-as-the-best-19279518" className='press-item'>
        <img width="90%" src={ pressBustle } alt="Press Bustle" />
        <p>"The Copper Arch Supports Your Feet Will Love"</p>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.buzzfeed.com/alexandranapoli/things-that-must-have-been-designed-by-geniuses-520" className="press-item">
        <img width="90%" src={ pressBuzzFeed } alt="Press Buzzfeed" />
        <p>"21 Things That Must Have Been Designed By Geniuses"</p>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://www.elitedaily.com/p/39-strange-things-on-amazon-that-reviewers-are-seriously-passionate-about-16963361" className='press-item'>
        <img width="100%" src={ pressElite } alt="Press Elite Daily" />
        <p>"39 Things Reviewers Are Seriously Passionate About"</p>
      </a>
    </div>
  </PressItemsBlockStyle>
)

const BenefitsBlock = () => (
  <ImitationsBlockStyle>
    <div className='grid-contain'>
      <div className='grid'>
        <div className='block'>
          <img width="75" src={ Icon1 } alt='banner' />
          <h2>Highest Copper Content Guaranteed</h2>
          <p>Our products are constructed with <span>85% copper-infused nylon.</span>
               This innovative approach brings you the maximum benefit, enhanced durability and a superior experience.
            </p>
        </div>
        <div className='block'>
          <img width="75" src={ Icon2 } alt='banner' />
          <h2>Antimicrobial</h2>
          <p>Antimicrobial properties provide maximum benefits, extending the life of the product for a superior experience.</p>
        </div>
        <div className='block'>
          <img width="75" src={ Icon3 } alt='banner' />
          <h2>Anti Odor</h2>
          <p>Our copper infused fabric helps keep bad odors away</p>
        </div>
      </div>
    </div>
  </ImitationsBlockStyle>
)

const LifestyleBlock = () => (
  <LifestyleBlockStyle></LifestyleBlockStyle>
)

const ImageStripBlock = () => (
  <ImageStripBlockStyle>
    <img width="100%" src={ lifestyleImage2 } alt="Lifestyle images" />
  </ImageStripBlockStyle>
)

const IndexPage = () => {

  return (
    <>
      <SEO title="Home" keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />

      <HeroBlock
        image={salesHome}
        subtitle='Surprise Summer Sale'
        title={`24-Hours Only: 33% off Arm Products`}
        tagline={`Support your shoulders, elbow and more!`}
        cta='Shop Now'
        to='/collections/arms/'
      />

      <BestSellers />

      <DrewSticker
        showName
        reverse='true'
        img={ drewSticker2 }
        bg={ skyBlue }
        caption="“It doesn’t matter if you're a pro athlete, weekend warrior, or weekday worker - Copper Compression is for everyone”"
      />

      <ReviewBlock
        mirror
        tightBaseline
        destination='/products/copper-compression-fit-for-hands/'
        caption='Copper Compression Arthritis Gloves'
        bgColor='#f3f3f3'
        boximage={ handReview }
        title='Copper-infused Half Finger Gloves'
        reviewText="When I wear Copper Compression gloves, I don't feel like I'm injured, I feel like I'm enhanced."
        reviewCredit="John, Vermont"
        reviewCount="1 of 9,000 Reviews"
      />

      <ImageStripBlock />
      <LifestyleBlock />
      <GuaranteeBlock />
      <ImitationsBlock />
      <PressBlock />
      <BenefitsBlock />

    </>
  )
}

export default IndexPage
